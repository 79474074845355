import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

// This script provides switching between different product variant colors.
//
// <div data-controller="select-color"
//      data-select-color-active-class="color--active"
//      data-action="hashchange@window->select-color#changeColor">
//   {% for variant in product.variants %}
//     <div data-select-color-target="button"
//          data-action="click->select-color#changeColor"
//          data-select-color-variant-id-param="{{ variant.id }}">
//          data-select-color-variant-color-slug-param="{{ variant.color.slug }}">
//       Variant button
//     <div>
//   {% endfor %}

//   {% for variant in product.variants %}
//     <div data-select-color-target="variantData" data-variant-id="{{ variant.id }}">
//       Variant picture
//     <div>
//   {% endfor %}

//   {% for variant in product.variants %}
//     <div data-select-color-target="variantData" data-variant-id="{{ variant.id }}">
//       Variant add to cart button
//     <div>
//   {% endfor %}
// </div>
//
export default class extends Controller {
  static targets = [
    'button',
    'variantData'
  ]

  static classes = ['active']

  connect () {
    const variantColorSlug = window.location.hash.replace(/^#/, '')
    this.activateColor(variantColorSlug)
  }

  // Handles both `hashchange` and `click` events.
  changeColor (e) {
    let { variantColorSlug, variantId } = e.params

    if (e.newURL) {
      variantColorSlug ||= new URL(e.newURL).hash.replace(/^#/, '')
    } else if (!_.isEmpty(variantColorSlug)) {
      window.location.hash = `#${variantColorSlug}`
    }

    this.activateColor(variantColorSlug, variantId)
  }

  activateColor (variantColorSlug, variantId) {
    let selectedButtonTarget = this.buttonTargets[0]

    if (!selectedButtonTarget) { return }

    this.buttonTargets.forEach((target) => {
      if (variantColorSlug
        ? target.dataset.selectColorVariantColorSlugParam === variantColorSlug
        : target.dataset.selectColorVariantIdParam === variantId) {
        selectedButtonTarget = target
      } else {
        target.classList.remove(this.activeClass)
      }
    })

    selectedButtonTarget.classList.add(this.activeClass)
    variantId ||= selectedButtonTarget.dataset.selectColorVariantIdParam

    this.variantDataTargets.forEach((target) => {
      target.hidden = target.dataset.variantId !== variantId
    })
  }
}
