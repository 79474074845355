import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['box', 'form']

  accept () {
    this.setCookie()
    this.formTarget.requestSubmit()
  }

  decline (event) {
    event.preventDefault()
    event.stopPropagation()
    this.setCookie()
    this.boxTarget.remove()
  }

  setCookie () {
    Cookies.set('accept_website', true, { expires: 365 })
  }
}
