import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  onChange (event) {
    if (event.target.value !== '') {
      if (this.hasButtonTarget) {
        this.buttonTarget.disabled = false
      }
    } else {
      if (this.hasButtonTarget) {
        this.buttonTarget.disabled = true
      }
    }
  }
}
