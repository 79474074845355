import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  connect () {
    this.storageKey = `${this.formTarget.id}FormData`
    this.load()
  }

  save () {
    const form = new FormData(this.formTarget)
    let data = []

    for (const pair of form.entries()) {
      if (pair[0] !== 'authenticity_token' &&
            pair[0] !== 'orders_create[delivery_address][country]' &&
            pair[0] !== 'orders_create[accept_terms]') {
        data.push([pair[0], pair[1]])
      }
    }

    data = Object.fromEntries(data)
    localStorage.setItem(this.storageKey, JSON.stringify(data))
  }

  load () {
    const json = localStorage.getItem(this.storageKey)

    if (json) {
      const data = JSON.parse(json)
      const event = new Event('change', { bubbles: true })

      Object.entries(data).forEach(([name, value]) => {
        let input = this.formTarget.querySelector(`[type='radio'][name='${name}'][value='${CSS.escape(value)}']`)
        input ??= this.formTarget.querySelector(`[type='checkbox'][name='${name}']`)
        input ??= this.formTarget.querySelector(`[name='${name}']`)

        if (input) {
          if (input.type === 'radio') {
            const was = input.checked
            input.checked = true
            if (input.checked !== was) {
              input.dispatchEvent(event)
            }
          } else if (input.type === 'checkbox') {
            const was = input.checked
            input.checked = (value === '1')
            if (input.checked !== was) {
              input.dispatchEvent(event)
            }
          } else {
            const was = input.value
            input.value = value
            if (input.value !== was) {
              input.dispatchEvent(event)
            }
          }
        }
      })
    }
  }
}
