import { Controller } from '@hotwired/stimulus'
import { isMobile } from '../utils/screen'

const arrayMove = (arr, firstValue, secondValue) => {
  const firstIndex = arr.findIndex((el) => el === firstValue)
  const secondIndex = arr.findIndex((el) => el === secondValue)
  const newArray = [...arr];
  [newArray[firstIndex], newArray[secondIndex]] = [
    newArray[secondIndex],
    newArray[firstIndex]
  ]
  return newArray
}

export default class extends Controller {
  static targets = [
    'select',
    'column',
    'title',
    'colorLine',
    'colorVariant',
    'sticky'
  ]

  static values = {
    products: Array
  }

  connect () {
    const header = document.querySelector('.a-header')
    this.setStickyLine()
    if (header) {
      header.classList.add('a-header--no-sticky')
    }
    this.stickyTargets.forEach((el) => {
      const observer = new IntersectionObserver(
        ([e]) =>
          e.target.classList.toggle(
            'compare-select-column--sticky',
            e.intersectionRatio < 1
          ),
        { threshold: [1] }
      )
      observer.observe(el)
    })
  }

  setStickyLine () {
    let height = 48
    if (isMobile()) {
      this.selectTargets.forEach((el) => {
        if (el.getBoundingClientRect().height > height) {
          height = el.getBoundingClientRect().height
        }
      })
    }
    // this.stickyTarget.style = `top: ${height}px;`;
  }

  selectProduct (e) {
    const newValue = e.target.value
    let oldValue
    this.selectTargets.forEach((el) => {
      if (el !== e.target) {
        if (el.value === e.target.value) {
          el.dataset.prev = e.target.dataset.prev
          el.value = e.target.dataset.prev
          oldValue = e.target.dataset.prev
          e.target.dataset.prev = e.target.value
        }
      }
    })
    const newArray = arrayMove(this.productsValue, newValue, oldValue)
    this.columnTargets.forEach((el) => {
      const newIndex = newArray.findIndex((pid) => pid === el.dataset.pid)
      const oldIndex = this.productsValue.findIndex(
        (pid) => pid === el.dataset.pid
      )
      if (newIndex === 0) {
        el.classList.add('compare-bg')
        if (el.dataset.firstRow) {
          el.classList.add('compare-bg--first', 'md:px-8')
        } else {
          el.classList.add('px-4')
          el.classList.remove('px-0', 'md:px-8')
        }
      } else {
        if (el.dataset.firstRow) {
          el.classList.add('px-4')
          el.classList.remove('md:px-8')
        } else {
          el.classList.add('px-0')
          el.classList.remove('px-4')
        }
      }
      if (oldIndex === 0 && newIndex !== 0) {
        el.classList.remove('compare-bg', 'compare-bg--first')
      }
      if (oldIndex !== newIndex) {
        el.classList.remove(`col-start-${oldIndex + 1}`)
        el.classList.add(`col-start-${newIndex + 1}`)
      }
      if (!el.dataset.displaySkip) {
        if (newIndex === 1 || newIndex === 0) {
          el.classList.remove('hidden', 'md:hidden', 'lg:hidden')
        }
        if (newIndex === 2) {
          el.classList.add('hidden', 'md:flex')
          el.classList.remove('md:hidden', 'lg:hidden')
        }
        if (newIndex === 3) {
          el.classList.add('hidden', 'md:hidden', 'lg:flex')
          el.classList.remove('md:flex')
        }
        if (newIndex > 3) {
          el.classList.add('hidden')
          el.classList.remove('md:hidden', 'md:flex', 'lg:flex')
        }
      } else {
        if (newIndex === 1 || newIndex === 0) {
          el.classList.remove('hidden')
        } else {
          el.classList.add('hidden')
        }
      }
    })
    newArray.forEach((el, index) => {
      this.titleTargets.forEach((elT) => {
        if (
          (index === 0 || index === 1) &&
          elT.dataset.productIds.includes(el)
        ) {
          elT.classList.remove('hidden', 'md:hidden', 'lg:hidden')
          elT.classList.remove('hidden', 'md:hidden', 'lg:hidden')
          elT.dataset.index = index.toString()
        }
        if (
          index === 2 &&
          elT.dataset.productIds.includes(el) &&
          (elT.dataset.index === undefined || parseInt(elT.dataset.index) > 1)
        ) {
          elT.classList.remove('md:hidden', 'lg:hidden')
          elT.classList.add('hidden', 'md:flex')
          this.colorLineTarget.classList.remove('md:hidden', 'lg:hidden')
          this.colorLineTarget.classList.add('hidden', 'md:flex')
          elT.dataset.index = index.toString()
        }
        if (
          index === 3 &&
          elT.dataset.productIds.includes(el) &&
          (elT.dataset.index === undefined || parseInt(elT.dataset.index) > 2)
        ) {
          elT.classList.remove('lg:hidden')
          elT.classList.add('hidden', 'md:hidden', 'lg:flex')
          this.colorLineTarget.classList.remove('lg:hidden')
          this.colorLineTarget.classList.add('hidden', 'md:hidden', 'lg:flex')
          elT.dataset.index = index.toString()
        }
        if (
          index > 3 &&
          elT.dataset.productIds.includes(el) &&
          (elT.dataset.index === undefined || parseInt(elT.dataset.index) > 3)
        ) {
          elT.classList.add('hidden', 'md:hidden', 'lg:hidden')
          elT.classList.remove('md:flex', 'lg:flex')
          this.colorLineTarget.classList.add(
            'hidden',
            'md:hidden',
            'lg:hidden'
          )
          this.colorLineTarget.classList.remove('md:flex', 'lg:flex')
          elT.dataset.index = index.toString()
        }
      })
    })
    this.productsValue = newArray
  }
}
