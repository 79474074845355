import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['country', 'postcode', 'address', 'city', 'state']

  autocomplete = null

  initialize () {
    this.placeChanged = this.placeChanged.bind(this)
  }

  connect () {
    if (window.google && window.google.maps && window.google.maps.places && !this.autocomplete) {
      this.initAutocomplete()
    }
  }

  initAutocomplete () {
    this.autocomplete = new window.google.maps.places.Autocomplete(this.addressTarget, {
      componentRestrictions: { country: [this.countryCode()] },
      fields: ['address_components', 'name'],
      types: ['address']
    })
    this.autocomplete.addListener('place_changed', this.placeChanged)
  }

  setAutocompleteCountry () {
    if (this.autocomplete) {
      this.autocomplete.setComponentRestrictions({ country: [this.countryCode()] })
    }
  }

  placeChanged () {
    const place = this.autocomplete.getPlace()
    const address = this.placeToAddress(place.address_components)
    const event = new Event('change', { bubbles: true })

    this.postcodeTarget.value = address.postal_code ?? ''
    this.postcodeTarget.dispatchEvent(event)

    this.addressTarget.value = place.name ?? [address.street_number ?? '', address.route ?? ''].join(' ')
    this.addressTarget.dispatchEvent(event)

    this.cityTarget.value = address.locality ?? address.postal_town ?? address.sublocality_level_1 ?? ''
    this.cityTarget.dispatchEvent(event)

    if (this.hasStateTarget) {
      this.stateTarget.value = address.administrative_area_level_1 ?? ''
      this.stateTarget.dispatchEvent(event)
    }
  }

  placeToAddress (components) {
    return components.reduce((address, component) => (
      { ...address, [component.types[0]]: component.long_name }
    ), {})
  }

  countryCode () {
    return this.countryTarget.querySelector(':checked').dataset.code
  }
}
