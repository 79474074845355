import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = [
    'button',
    'paymentMethod'
  ]

  static values = {
    publishableKey: String
  }

  initialize () {
    this.processResult = this.processResult.bind(this)
  }

  async connect () {
    this.stripe = await loadStripe(this.publishableKeyValue)
  }

  async pay () {
    document.querySelector('#loader').classList.remove('hidden')
    const intentResponse = await this.createPaymentIntent()

    this.stripe.confirmKlarnaPayment(
      intentResponse.client_secret,
      {
        payment_method: {
          billing_details: {
            email: intentResponse.customer_email,
            address: {
              country: intentResponse.country_code
            }
          }
        },
        return_url: intentResponse.klarna_return_url
      }
    ).then(this.processResult)
  }

  async createPaymentIntent () {
    this.paymentMethodTarget.value = 'klarna'

    const { form } = this.paymentMethodTarget
    const formData = new FormData(form)
    const response = await fetch(form.action, {
      method: form.method,
      body: formData
    })
    return await response.json()
  }

  async processResult (result) {
    if (result.error) {
      const messageBox = document.querySelector('#stripe-message-error')
      const text = document.querySelector('#stripe-message-error-text')
      text.innerHTML = result.error.message
      document.querySelector('#loader').classList.add('hidden')
      messageBox.dataset.messageTriggerValue = true
    }
  }
}
