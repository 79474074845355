import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper/bundle'

// Wraps Swiper in Stimulus
//
// <div data-controller="swiper" class="swiper-container" data-swiper-options-value='{"direction": "vertical"}'>
//   <div class="swiper-wrapper">
//     <div class="swiper-slide">Slide 1</div>
//     <div class="swiper-slide">Slide 2</div>
//   </div>
// </div>
export default class extends Controller {
  static swiper
  static values = {
    options: Object
  }

  connect () {
    this.swiper = new Swiper(this.element, {
      ...this.optionsValue
    })
  }

  disconnect () {
    this.swiper.destroy()
    this.swiper = undefined
  }
}
