import { Controller } from '@hotwired/stimulus'
import { showErrorBox } from '../utils/dom'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'billingAddressBlock',
    'payButton',
    'paymentMethod',
    'recalculateButton',
    'updateCountryButton',
    'countrySelect',
    'calculatedValue',
    'acceptTerms',
    'voucherCode'
  ]

  initialize () {
    this.recalculate = _.debounce(this.recalculate, 500)
  }

  updateCountry () {
    this.updateCountryButtonTarget.click()
    this.calculatedValueTargets.forEach(element => element.classList.add('a-value--load'))
  }

  resetVoucher (e) {
    this.voucherCodeTarget.disabled = false
    this.voucherCodeTarget.value = ''
    this.recalculate(e)
  }

  recalculate (e) {
    if (e.target !== this.countrySelectTarget) {
      this.recalculateButtonTarget.click()
      this.calculatedValueTargets.forEach(element => element.classList.add('a-value--load'))
    }
  }

  toggleBillingForm (e) {
    if (e.target.checked) {
      this.billingAddressBlockTarget.classList.add('hidden')
    } else {
      this.billingAddressBlockTarget.classList.remove('hidden')
    }
  }

  async requestEnd (event) {
    document.querySelector('#loader').classList.add('hidden')

    if (!event.detail.fetchResponse.succeeded && event.detail.fetchResponse.response.status === 422) {
      const json = await event.detail.fetchResponse.response.clone().json()
      showErrorBox('stripe-message-error', json.message)
    }
  }

  payWithCard (e) {
    document.querySelector('#loader').classList.remove('hidden')

    e.preventDefault()
    this.paymentMethodTarget.value = 'stripe'
    this.paymentMethodTarget.form.requestSubmit()
  }

  toMoney (value, currency) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    })
    return formatter.format(value)
  }
}
