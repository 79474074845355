import { Controller } from '@hotwired/stimulus'
import { isMobile } from '../utils/screen'

export default class extends Controller {
  static targets = [
    'button',
    'content',
    'arrow',
    'hiddenElement',
    'showElement'
  ]

  static values = {
    trigger: Boolean
  }

  triggerValueChanged () {
    if (this.triggerValue && isMobile()) {
      this.contentTarget.classList.remove('hidden')
      this.arrowTarget.classList.add('-rotate-180')
      this.hiddenElementTargets.forEach((el) => {
        el.classList.add('opacity-0')
      })
      this.showElementTargets.forEach((el) => {
        el.classList.remove('hidden')
      })
    }
  }

  toggle () {
    if (isMobile()) {
      this.contentTarget.classList.toggle('hidden')
      this.arrowTarget.classList.toggle('-rotate-180')
      this.hiddenElementTargets.forEach((el) => {
        el.classList.toggle('opacity-0')
      })
      this.showElementTargets.forEach((el) => {
        el.classList.toggle('hidden')
      })
    }
  }
}
