import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'toggle',
    'button',
    'message'
  ]

  disableButton (e) {
    this.buttonTarget.disabled = true
  }

  toggleForm (e) {
    e.preventDefault()

    this.toggleTargets.forEach(target => { target.hidden = !target.hidden })
  }
}
