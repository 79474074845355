import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

// This controller is used to update total quantity on the cart icon.
// If quantity is 0 - it will hide the quantity container.
//
//   <a href="/cart">
//     <span class="count"
//       data-controller="cart-icon"
//       data-cart-icon-target="totalQuantity totalQuantityWrapper"
//       data-action="cart-quantity-changed@window->cart-icon#update"
//       {% if lumie.cart.total_quantity == 0 %}hidden{% endif %}
//     >
//       {{ lumie.cart.total_quantity }}
//     </span>
//   </a>
//
export default class extends Controller {
  static targets = [
    'totalQuantity',
    'totalQuantityWrapper'
  ]

  static observer

  initialize () {
    this.observer = new MutationObserver(this.observeQuantityTargetMutations.bind(this))
  }

  observeQuantityTargetMutations (mutationsList, observer) {
    if (this.hasTotalQuantityWrapperTarget) {
      this.totalQuantityWrapperTarget.hidden = _.trim(this.totalQuantityTarget.innerHTML) === '0'
    }
  }

  totalQuantityTargetConnected (target) {
    this.observer.observe(target, { characterData: false, childList: true, attributes: false })
  }

  update (e) {
    const { totalQuantity } = e.detail
    this.totalQuantityTarget.innerHTML = totalQuantity
  }
}
