import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'counter']

  initialize () {
    this.update = this.update.bind(this)
  }

  connect () {
    this.update()
    this.inputTarget.addEventListener('change', this.update)
    this.inputTarget.addEventListener('keyup', this.update)
  }

  disconnect () {
    this.inputTarget.removeEventListener('change', this.update)
    this.inputTarget.removeEventListener('keyup', this.update)
  }

  update () {
    this.counterTarget.innerHTML = this.inputTarget.value.length.toString()
  }
}
