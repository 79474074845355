import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'filter',
    'item'
  ]

  static classes = ['active']

  filter (e) {
    e.preventDefault()

    this.filterTargets.forEach((target) => {
      if (target === e.currentTarget) {
        target.classList.add(this.activeClass)
      } else {
        target.classList.remove(this.activeClass)
      }
    })

    this.itemTargets.forEach((item) => {
      item.hidden = e.params.value ? !JSON.parse(item.dataset.filters).includes(e.params.value) : false
    })
  }
}
