import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

// The main provider of Google Tag Manager events. `window.dataLayer` should be initialized already on the page.
// Then there are to ways to provide an event:
//
//   <div data-controller="gtm-events" data-gtm-events-event-value="event_name"
//        data-gtm-events-payload-value="<%= some_data.to_json %>">
//
// In this case the event will be fired on controller being connected to the element. Or:
//
//   <a data-controller="gtm-events" data-gtm-events-event-value="event_name"
//      data-gtm-events-payload-param="<%= some_data.to_json %>" data-action="gtm-events#push"></a>
//
// In this case the event will be pushed on the link click or any other JS event.
//
export default class extends Controller {
  static values = {
    event: String,
    payload: Object
  }

  connect () {
    if (!_.isEmpty(this.payloadValue)) {
      this.push({ params: { payload: this.payloadValue } })
    }
  }

  push (e) {
    if (!_.isEmpty(this.eventValue) && !_.isEmpty(e.params.payload)) {
      window.dataLayer ||= []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        correlation_id: Math.random(),
        event: this.eventValue,
        ...e.params.payload
      })
    }
  }
}
