import { Controller } from '@hotwired/stimulus'
import { enable, disable } from '../utils/scroll-lock'

export default class extends Controller {
  static targets = ['box', 'shadow', 'header']

  connect () {
    this.mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)')
  }

  showMenu () {
    if (!this.mobile.matches) {
      enable()
      this.headerTarget.classList.add('a-header--active')
      this.shadowTarget.classList.add('shadow-background--show')
    }
  }

  openLink () {
    disable()
    if (!this.mobile.matches) {
      disable()
      this.headerTarget.classList.remove('a-header--active')
      this.shadowTarget.classList.remove('shadow-background--show')
    } else {
      this.boxTarget.classList.toggle('a-menu-box--hide')
    }
  }

  hideMenu () {
    if (!this.mobile.matches) {
      disable()
      this.headerTarget.classList.remove('a-header--active')
      this.shadowTarget.classList.remove('shadow-background--show')
    }
  }

  toggleMenu (e) {
    if (this.hasBoxTarget && this.mobile.matches) {
      e.preventDefault()
      if (this.boxTarget.classList.contains('a-menu-box--hide')) {
        enable()
      } else {
        disable()
      }
      this.boxTarget.classList.toggle('a-menu-box--hide')
      return false
    }
  }
}
