import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: 'https://1a0352b7728ed567bc2a35b7d50ab48d@o4505876641480704.ingest.sentry.io/4505879344644096',
  enabled: process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test',
  environment: process.env.NODE_ENV,
  ignoreErrors: [/AbortError/, /NotAllowedError/, /Load failed/],
  sampleRate: 0.1,
  tracesSampleRate: 0
})
