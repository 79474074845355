import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  connect () {
    const a = document.createElement('a')
    const supportsAR = !!a.relList.supports('ar')
    if (supportsAR && this.hasLinkTarget) {
      this.linkTarget.setAttribute('rel', 'ar')
    }
  }
}
