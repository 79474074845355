import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['node']

  connect () {
    this.header = document.querySelector('.a-header')
    this.header.classList.add('a-header--transparent')
    window.addEventListener('scroll', this.checkHeaderPosition.bind(this))
  }

  checkHeaderPosition () {
    if (this.nodeTarget.offsetHeight - 96 <= window.scrollY) {
      this.header.classList.remove('a-header--transparent')
    } else {
      this.header.classList.add('a-header--transparent')
    }
  }
}
