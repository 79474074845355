import { Controller } from '@hotwired/stimulus'
import submitJsonForm from '../utils/submitJsonForm'

// <div data-controller="form" data-form-form-field-outlet="#super-form [data-controller~='form-field']">
//   <div data-form-target="wrapper">
//     <form id="super-form" data-form-target="form" data-action="submit->form#handleSubmit">
//       <div data-controller="form-field" data-form-field-target="wrapper" data-form-field-error-key-value="email">
//         <input type="text" name="email"
//                data-form-field-target="input"
//                data-action="change->form-field#clearError keyup->form-field#clearError">
//         <div data-form-field-target="error"></div>
//       </div>
//     </form>
//   </div>
//   <div data-form-target="successMessage" hidden>
//     Success!!
//   </div>
// </div>
export default class extends Controller {
  static outlets = [
    'form-field'
  ]

  static targets = [
    'wrapper',
    'successMessage',
    'form',
    'submitButton'
  ]

  connect () {
    this.enableSubmitButton()
  }

  async handleSubmit (e) {
    if (!this.hasFormTarget) { return }

    e.preventDefault()

    this.disableSubmitButton()

    const response = await submitJsonForm(this.formTarget)

    if (response.success) {
      const formData = new FormData(this.formTarget)
      const event = new CustomEvent('form-data-submitted', { detail: Object.fromEntries(formData.entries()) })
      window.dispatchEvent(event)

      if (response.redirect_url) {
        window.location = response.redirect_url
      } else {
        this.resetForm()
        if (this.hasWrapperTarget) {
          this.wrapperTarget.hidden = true
        }
        if (this.hasSuccessMessageTarget) {
          this.successMessageTarget.hidden = false
        }
      }
    } else {
      this.assignErrors(response.errors)
    }

    this.enableSubmitButton()
  }

  disableSubmitButton () {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
    }
  }

  enableSubmitButton () {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false
    }
  }

  resetForm () {
    this.formTarget.reset()
  }

  assignErrors (errors) {
    this.formFieldOutlets.forEach((formFieldOutlet) => {
      formFieldOutlet.pickError(errors)
    })
  }
}
