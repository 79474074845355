import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'changeQuantityForm',
    'addedFrame',
    'removedFrame'
  ]

  startRemoveItem () {
    this.addedFrameTarget.classList.add('cart-item__added--hide')
    this.removedFrameTarget.classList.remove('cart-item__removed--hide')
    this.removedFrameTarget.parentNode.classList.remove('discounted')
    this.setLoadingValue()
  }

  startReturnItem () {
    this.addedFrameTarget.classList.remove('cart-item__added--hide')
    this.removedFrameTarget.classList.add('cart-item__removed--hide')
    this.setLoadingValue()
  }

  startChangeQuantity () {
    this.setLoadingValue()
  }

  changeQuantity () {
    this.changeQuantityFormTarget.requestSubmit()
  }

  setLoadingValue () {
    const elements = document.querySelectorAll(
      '.line-value, .cart-item__price-total, .cart-item__price-per-unit, .cart-item__price-save, .cart-show-delivery__terms, .cart-item__warning-message'
    )
    elements.forEach((el) => {
      el.classList.remove('a-value--new')
      el.classList.add('a-value--load')
      el.innerHTML = ''
    })
  }
}
