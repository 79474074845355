import { disable, enable } from '../utils/scroll-lock'
import { Controller } from '@hotwired/stimulus'
import { isMobile } from '../utils/screen'

export default class extends Controller {
  static targets = ['popup', 'popupButtonToggle']

  connect () {
    this.tablet = window.matchMedia('(min-width: 768px)')
    if (this.hasPopupTarget) {
      document.addEventListener('click', (evt) => this.closePopup(evt), false)
    }
  }

  disconnect () {
    document.removeEventListener('click', (evt) => this.closePopup(evt), false)
  }

  togglePopup (e) {
    e.preventDefault()
    if (isMobile()) {
      if (this.popupTarget.classList.contains('hidden')) {
        this.popupTarget.classList.remove('hidden')
        if (!this.tablet.matches) {
          enable()
        }
      } else {
        this.hidePopup(e)
      }
    }
  }

  hidePopup () {
    this.popupTarget.classList.add('hidden')
    disable()
  }

  closePopup (event) {
    const productsButton = document.querySelector(
      '.cart-show-sad__popup-content'
    )
    let targetElement = event.target
    do {
      if (
        targetElement === productsButton ||
        (this.hasPopupButtonToggleTarget &&
          targetElement === this.popupButtonToggleTarget)
      ) {
        event.preventDefault()
        return
      }
      targetElement = targetElement.parentNode
    } while (targetElement)
    if (!this.popupTarget.classList.contains('hidden')) {
      this.hidePopup(event)
    }
  }
}
