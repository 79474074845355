import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'value', 'wrapper', 'form']

  static values = {
    block: String,
    destination: String
  }

  connect () {
    this.updateValueAndWidth = this.updateValue.bind(this)
    window.addEventListener('resize', this.updateValueAndWidth)
    this.updateValue({ value: undefined })
  }

  disconnect () {
    window.removeEventListener('resize', this.updateValueAndWidth)
  }

  onChange (e) {
    const value = e.target.value
    this.updateValue({ value })
    this.updateCountry()
  }

  updateValue ({ value }) {
    const optionValue = value || this.selectTarget.value
    const mobile = window.matchMedia('(min-width: 0px) and (max-width: 1023px)').matches
    const option = document.getElementById(`${this.blockValue}-${optionValue}`)
    const shortName = option.getAttribute('data-short-name')
    const name = option.getAttribute('data-name')
    if ((this.destinationValue !== 'm-menu') && ((this.blockValue !== 'product' && name.length > 14 && mobile) || (this.blockValue === 'product' && name.length > 20 && mobile))) {
      this.valueTarget.innerHTML = shortName
    } else {
      this.valueTarget.innerHTML = name
    }
    this.wrapperTarget.style = `max-width: ${this.valueTarget.getBoundingClientRect().width + 30}px`
    this.selectTarget.style = `width: ${this.valueTarget.getBoundingClientRect().width + 30}px`
  }

  updateCountry () {
    this.formTarget.requestSubmit()
  }
}
