// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

import * as Sentry from '@sentry/browser'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.debug = process.env.NODE_ENV === 'development'

application.handleError = (error, message, detail) => {
  console.warn(error, message, detail)
  Sentry.captureException(error, { extra: { message, detail } })
}
