// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import './utils/sentry'
import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import smoothscroll from 'smoothscroll-polyfill'
import 'form-request-submit-polyfill'
import Swiper from 'swiper/bundle'

import 'controllers'
import 'stylesheets/application.css'
import 'swiper/css/bundle'

window.Swiper = Swiper

require.context('./images', true)
require.context('./fonts', true)

Rails.start()
smoothscroll.polyfill()

document.addEventListener('turbo:visit', () => {
  window.turboReferrer = document.location.href
})
