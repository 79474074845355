import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'address',
    'address2',
    'city',
    'state',
    'postcode',
    'country',
    'output',
    'enterButton',
    'changeButton'
  ]

  connect () {
    this.update()
  }

  update () {
    const countryName = this.countryTarget.querySelector(':checked').text
    const values = [
      this.addressTarget.value,
      this.address2Target.value,
      this.cityTarget.value,
      this.hasStateTarget ? this.stateTarget.value : null,
      countryName,
      this.postcodeTarget.value
    ]
    const summary = _.reject(values, _.isEmpty).join(', ')

    if (summary === countryName) {
      this.enterButtonTarget.classList.remove('hidden')
      this.changeButtonTarget.classList.add('hidden')
    } else {
      this.enterButtonTarget.classList.add('hidden')
      this.changeButtonTarget.classList.remove('hidden')
    }

    this.outputTarget.innerHTML = summary
  }
}
