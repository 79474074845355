import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'menu',
    'next',
    'prev',
    'menuWrapper',
    'menuTrigger',
    'item'
  ]

  static values = {
    menuSize: Number
  }

  connect () {
    this.links = document.querySelectorAll('.catalog-menu__item')
    this.checkCurrentScroll()
    this.updateScrollButton = this.checkCurrentScroll.bind(this)
    window.addEventListener('resize', this.updateScrollButton)
    window.addEventListener('scroll', this.updateMenuPosition.bind(this))
    this.menuTarget.addEventListener('scroll', this.updateScrollButton)
    this.menuClickListener()
    this.checkMenuLink()
    this.pageLoad()
  }

  pageLoad () {
    if (window.location.hash) {
      this.scrollToElement(window.location.hash)
      this.updateMenuPosition()
    }
  }

  disconnect () {
    window.removeEventListener('resize', this.updateScrollButton)
    this.menuTarget.removeEventListener('scroll', this.updateScrollButton)
  }

  menuClickListener () {
    const scrollTo = this.scrollToElement.bind(this)
    this.itemTargets.forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        const id = `#${this.getAttribute('href').split('#')[1]}`
        scrollTo(id, this.getAttribute('href'))
      })
    })
  }

  scrollToElement (id, href) {
    const element = document.querySelector(id)
    const y = element.getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
    if (href) {
      window.location = href
    }
  }

  updateMenuPosition () {
    if (this.hasMenuTriggerTarget) {
      if (this.menuTriggerTarget.offsetTop - 48 < window.scrollY) {
        this.menuWrapperTarget.classList.add('catalog-menu--sticky')
      } else {
        this.menuWrapperTarget.classList.remove('catalog-menu--sticky')
      }
      this.checkMenuLink()
    }
  }

  checkCurrentScroll () {
    const left = this.menuTarget.scrollLeft
    const maxWidth = this.menuTarget.scrollWidth
    const screenWidth = this.menuTarget.getBoundingClientRect().width
    const currentScroll = screenWidth + left
    if (currentScroll > 24 && currentScroll < maxWidth - 24) {
      this.prevTarget.classList.remove('catalog-menu__nav--hide')
      this.nextTarget.classList.remove('catalog-menu__nav--hide')
    }
    if (left < 24) {
      this.prevTarget.classList.add('catalog-menu__nav--hide')
    }
    if (currentScroll > maxWidth - 24) {
      this.nextTarget.classList.add('catalog-menu__nav--hide')
    }
    if (maxWidth < this.menuTriggerTarget.offsetWidth) {
      this.nextTarget.classList.add('catalog-menu__nav--hide')
      this.prevTarget.classList.add('catalog-menu__nav--hide')
    }
    this.menuWrapperTarget.style = `width: ${this.menuTriggerTarget.offsetWidth}px; left: calc((100vw - ${this.menuTriggerTarget.offsetWidth}px) / 2);`
  }

  checkMenuLink () {
    let prevLink
    this.links.forEach((el) => {
      const id = `#${el.getAttribute('href').split('#')[1]}`
      const anchorStart = document.querySelector(id + '-real')
      const anchorEnd = document.querySelector(id + '_end-real')
      if (anchorStart && anchorEnd) {
        if (
          anchorStart.offsetTop <= window.scrollY + 94 &&
          anchorEnd.offsetTop > window.scrollY
        ) {
          el.classList.add('catalog-menu__item--active')
          this.menuTarget.scrollTo({
            left: prevLink ? prevLink.offsetWidth : 0,
            behavior: 'smooth'
          })
        } else {
          el.classList.remove('catalog-menu__item--active')
        }
      }
      prevLink = el
    })
  }

  toEnd () {
    const maxWidth = this.menuTarget.scrollWidth
    const screenWidth = this.menuTarget.getBoundingClientRect().width
    this.menuTarget.scrollTo({
      top: 0,
      left: maxWidth - screenWidth,
      behavior: 'smooth'
    })
  }

  toStart () {
    this.menuTarget.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}
