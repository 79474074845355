import { Controller } from '@hotwired/stimulus'
import submitJsonForm from '../utils/submitJsonForm'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'formWrapper',
    'form',
    'invite',
    'button',
    'error',
    'success'
  ]

  async onSubmit (e) {
    e.preventDefault()

    const response = await submitJsonForm(this.formTarget)

    if (response.success) {
      const formData = new FormData(this.formTarget)
      const event = new CustomEvent('form-data-submitted', { detail: Object.fromEntries(formData.entries()) })
      window.dispatchEvent(event)

      this.setError(null)
      if (this.hasInviteTarget) {
        this.inviteTarget.hidden = true
      }
      this.successTarget.hidden = false
      if (this.hasFormWrapperTarget) {
        this.formWrapperTarget.hidden = true
      } else {
        this.formTarget.hidden = true
      }
      this.formTarget.reset()
    } else {
      this.setError(response.error)
    }
  }

  onChange (event) {
    this.setError(null)

    if (_.isEmpty(event.target.value)) {
      this.buttonTarget.disabled = true
    } else {
      this.buttonTarget.disabled = false
    }
  }

  setError (error) {
    if (error) {
      this.errorTarget.innerHTML = error
      this.errorTarget.hidden = false
    } else {
      this.errorTarget.innerHTML = ''
      this.errorTarget.hidden = true
    }
  }
}
