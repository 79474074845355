import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'
import { getMeta } from '../utils/dom'

const style = {
  base: {
    iconColor: '#FFB81C',
    color: '#4A4847',
    fontWeight: 400,
    fontFamily: 'Averta, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':-webkit-autofill': {
      color: '#fce883'
    },
    '::placeholder': {
      color: '#ACA8A5'
    }
  },
  invalid: {
    iconColor: 'red',
    color: 'red'
  }
}

export default class extends Controller {
  static targets = ['form', 'error', 'label', 'button', 'wrapper']

  static values = {
    publishableKey: String,
    confirmUrl: String,
    clientSecret: String,
    postcode: String
  }

  async connect () {
    this.scrollToStripe()
    await this.createStripeInstance()
    this.createElements()
    this.createCard()
    this.card.mount('#card-element')
    this.formTarget.addEventListener('submit', (e) => {
      e.preventDefault()
    })
    this.card.on(
      'focus',
      () => {
        this.wrapperTarget.classList.add('stripe-wrapper--focus')
      },
      this
    )
    this.card.on(
      'blur',
      () => {
        this.wrapperTarget.classList.remove('stripe-wrapper--focus')
      },
      this
    )
    this.card.on(
      'change',
      (event) => {
        this.buttonTarget.disabled = !event.complete

        if (event.error) {
          this.labelTarget.classList.add('hidden')
          this.errorTarget.innerHTML = event.error.message
          this.errorTarget.classList.remove('hidden')
        } else {
          this.labelTarget.classList.remove('hidden')
          this.errorTarget.classList.add('hidden')
        }
      },
      this
    )
  }

  scrollToStripe () {
    const scrollElement = document.getElementById('card-element')
    scrollElement.scrollIntoView({ block: 'center' })
  }

  pay () {
    this.payWithCard(this.card, this.clientSecretValue)
  }

  createCard () {
    let params = { style }
    if (this.postcodeValue) {
      params = { ...params, value: { postalCode: this.postcodeValue } }
    }
    this.card = this.elements.create('card', params)
  }

  async createStripeInstance () {
    this.stripe = await loadStripe(this.publishableKeyValue)
  }

  createElements () {
    this.elements = this.stripe.elements()
  }

  payWithCard (card, clientSecret) {
    document.querySelector('#loader').classList.remove('hidden')
    this.stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card
      }
    }).then(this.resultProcessing.bind(this))
  }

  async resultProcessing (result) {
    if (result.error) {
      const messageBox = document.querySelector('#stripe-message-error')
      const text = document.querySelector('#stripe-message-error-text')
      text.innerHTML = result.error.message
      document.querySelector('#loader').classList.add('hidden')
      messageBox.dataset.messageTriggerValue = true
    } else {
      const csrfToken = getMeta('csrf-token')
      const response = await fetch(this.confirmUrlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ payment_intent: result.paymentIntent })
      })
      const responseData = await response.json()
      if (responseData.redirect_url) {
        window.location = responseData.redirect_url
      }
    }
  }
}
