import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  start (event) {
    const elements = document.querySelectorAll(
      '.line-value, .cart-item__price-total, .cart-item__price-per-unit, .cart-item__price-save, .cart-show-delivery__terms, .cart-item__warning-message'
    )
    elements.forEach((el) => {
      el.setAttribute('style', `width:${el.offsetWidth}px`)
      el.classList.remove('a-value--new')
      el.classList.add('a-value--load')
      el.innerHTML = ''
    })
  }
}
