export const getMeta = (metaName) => {
  const metas = document.getElementsByTagName('meta')
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content')
    }
  }
  return ''
}

export const showErrorBox = (id, title = '', text = '') => {
  const errorBox = document.getElementById(id)
  if (title) {
    const errorTitle = document.getElementById(`${id}-title`)
    errorTitle.innerText = title
  }
  if (text) {
    const errorText = document.getElementById(`${id}-text`)
    errorText.innerText = text
  }
  errorBox.dataset.messageTriggerValue = true
}
