import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'
import { getMeta } from '../utils/dom'

export default class extends Controller {
  static targets = [
    'paymentMethod',
    'button',
    'applePayLogo',
    'googlePayLogo'
  ]

  static values = {
    publishableKey: String,
    country: String,
    currency: String,
    total: Number,
    shippingOptions: Array
  }

  initialize () {
    this.handlePaymentMethod = this.handlePaymentMethod.bind(this)
  }

  async connect () {
    this.stripe = await loadStripe(this.publishableKeyValue)

    if (this.totalValue) {
      this.paymentRequest = this.stripe.paymentRequest({
        country: this.countryValue,
        currency: this.currencyValue,
        total: {
          label: 'Order total',
          amount: this.totalValue
        },
        disableWallets: ['link']
      })

      this.paymentRequest.on('paymentmethod', this.handlePaymentMethod)

      const result = await this.paymentRequest.canMakePayment()

      if (result) {
        this.buttonTarget.hidden = false
        if (result.applePay) {
          this.applePayLogoTarget.hidden = false
        }
        if (result.googlePay) {
          this.googlePayLogoTarget.hidden = false
        }
      }
    }
  }

  pay (e) {
    e.preventDefault()

    this.paymentRequest.show()
  }

  async handlePaymentMethod (event) {
    document.querySelector('#loader').classList.remove('hidden')
    const intentResponse = await this.createPaymentIntent(event.methodName)

    const { paymentIntent, error } = await this.stripe.confirmCardPayment(
      intentResponse.client_secret,
      { payment_method: event.paymentMethod.id }
    )

    if (error) {
      event.complete('fail')
      document.querySelector('#loader').classList.add('hidden')
    } else {
      event.complete('success')

      const csrfToken = getMeta('csrf-token')
      const response = await fetch(intentResponse.stripe_confirm_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ payment_intent: paymentIntent })
      })
      const responseData = await response.json()

      if (responseData.redirect_url) {
        window.location = responseData.redirect_url
      }
    }
  }

  async createPaymentIntent (methodName) {
    this.paymentMethodTarget.value = methodName

    const { form } = this.paymentMethodTarget
    const formData = new FormData(form)
    const response = await fetch(form.action, {
      method: form.method,
      body: formData
    })
    return await response.json()
  }
}
