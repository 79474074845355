import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'formCountry',
    'index'
  ]

  startChangeCountry () {
    const elements = document.querySelectorAll(
      '.line-value, .cart-item__price-total, .cart-item__price-per-unit, .cart-item__price-save, .cart-show-delivery__terms, .cart-item__warning-message'
    )
    elements.forEach((el) => {
      if (
        el.classList.contains('cart-show-delivery__terms') ||
        el.classList.contains('cart-show-sad__container')
      ) {
        el.setAttribute(
          'style',
          `height:${el.offsetHeight}px;width:${el.offsetWidth}px;`
        )
      } else {
        el.setAttribute('style', `width:${el.offsetWidth}px`)
      }
      el.classList.remove('a-value--new')
      el.classList.add('a-value--load')
      el.innerHTML = ''
    })
  }

  changeCountry () {
    this.formCountryTarget.requestSubmit()
  }
}
