export default async function (form) {
  const formData = new FormData(form)
  const response = await fetch(form.action, {
    method: form.method,
    headers: { Accept: 'application/json' },
    body: formData
  })

  return await response.json()
}
