import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'buttonShow', 'buttonHide', 'textarea', 'value']

  connect () {
    if (this.hasValueTarget) {
      if (this.valueTarget.value !== '') {
        this.show()
      }
    }
  }

  show (e) {
    if (e) {
      e.preventDefault()
    }
    this.buttonShowTarget.classList.add('checkout-show__button-optional--hide')
    if (this.hasInputTarget) {
      this.inputTarget.classList.remove('a-input--hide')
    }
    if (this.hasTextareaTarget) {
      this.textareaTarget.classList.remove('a-textarea--hide')
    }
  }

  hide (e) {
    e.preventDefault()
    this.buttonShowTarget.classList.remove(
      'checkout-show__button-optional--hide'
    )
    if (this.hasInputTarget) {
      this.inputTarget.classList.add('a-input--hide')
    }
    if (this.hasTextareaTarget) {
      this.textareaTarget.classList.add('a-textarea--hide')
      this.textareaTarget.value = ''
    }
    if (this.hasValueTarget) {
      this.valueTarget.value = ''
      const event = new Event('change', { bubbles: true })
      this.valueTarget.dispatchEvent(event)
    }
  }
}
